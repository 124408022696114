import React, { useState, useEffect } from 'react';
import { useElements, useStripe, CardElement } from '@stripe/react-stripe-js';
import { EDonationProfile, ESnapshotExists } from '../types';
import { Functions } from '../Constants';
import Firebase from '../Firebase';
import { Card, Bank, ThankYou } from './icons';

const MONTHLY_DONATION = 'MONTHLY_DONATION';
const ONE_TIME_DONATION = 'ONE_TIME_DONATION';

const AMOUNTS_STEP = 'AMOUNTS_STEP';
const INFORMATION_STEP = 'INFORMATION_STEP';
const PAYMENT_STEP = 'PAYMENT_STEP';
const THANKYOU_STEP = 'THANKYOU_STEP';

const CARD = 'CARD';
const CHECK = 'CHECK';

const PaymentModal = ({
  org,
  close,
  inTestMode,
  statePrices,
  stateObj,
  isInHomePage
}: {
  org: ESnapshotExists<EDonationProfile>;
  close: Function;
  inTestMode: boolean;
  statePrices: { id: string; amount: string }[];
  isInHomePage?: boolean;
  stateObj: Record<string, any>;
}) => {
  const [step, setStep] = useState(AMOUNTS_STEP);
  const [err, setErr] = useState('');
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [donationType, setDonationType] = useState<string>('');
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [isSubscriber, setIsSubscriber] = useState(true);
  const [isSupporter, setIsSupporter] = useState(true);
  const [message, setMessage] = useState('');
  const [selectedPriceId, setSelectedPriceId] = useState('');
  const [selectedPriceAmt, setSelectedPriceAmt] = useState('');
  const [paymentMethod, setPaymentMethod] = useState<string>(CARD);
  const [emailError, setEmailError] = useState(false);
  const [paying, setPaying] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const { name, color } = org.data();

  useEffect(() => {
    const timer = setTimeout(() => {
      if (email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        setEmailError(!re.test(String(email).toLowerCase()));
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [email]);

  useEffect(() => {
    if (selectedPriceAmt && !donationType) setDonationType(MONTHLY_DONATION);
  }, [selectedPriceAmt]);

  const getTestEmail = (email: string) => {
    return email.split('@').join('+test@');
  };
  const getCheckText = () => {
    const memoLine = `with "${
      stateObj.memo_line ? stateObj.memo_line : ''
    }${name}" in the memo line, `;

    return `If you would like to pay by check, please write a check made out to "${
      stateObj.meta_title
    }" ${isInHomePage || stateObj.noMemoLine ? '' : memoLine}and mail to:`;
  };
  const handleSubmit = async () => {
    if (!donationType || !selectedPriceAmt) {
      alert('missing info');
      return;
    }
    setPaying(true);
    setErr('');

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement) || { token: '' }
    });

    if (error) {
      console.error('[error]', error);
      setErr(error.message || '');
      setPaying(false);
    } else {
      const donate = Firebase.functions().httpsCallable(Functions.donate);
      const result = await donate({
        paymentMethodId: paymentMethod?.id,
        amount: Number(selectedPriceAmt),
        customer_email: inTestMode ? getTestEmail(email) : email,
        customer_name: `${firstName} ${lastName}`,
        isAnonymous,
        isSubscriber,
        donationProfileId: org.id,
        priceId: selectedPriceId,
        isRecurring: donationType === MONTHLY_DONATION,
        inTestMode,
        state: stateObj.value,
        message
      });
      if (result.data.success) {
        setPaying(false);
        setStep(THANKYOU_STEP);
      } else {
        setErr(result.data.error);
        setPaying(false);
      }
    }
  };

  const registerUser = async () => {
    const donorQuery = await Firebase.firestore()
      .collection('donors')
      .where('email', '==', inTestMode ? getTestEmail(email) : email)
      .get();
    if (!donorQuery.docs.length) {
      const createCustomer = Firebase.functions().httpsCallable(
        Functions.createCustomer
      );
      const response = await createCustomer({
        name: `${firstName} ${lastName}`,
        email: inTestMode ? getTestEmail(email) : email,
        inTestMode
      });
      if (response.data.success) {
        const stripeId = response.data.stripeId;
        Firebase.firestore()
          .collection('donors')
          .add({
            email: inTestMode ? getTestEmail(email) : email,
            firstName,
            lastName,
            stripeId,
            state: stateObj.value
          });
      }
    }
  };
  const canGoNext = () => {
    if (paying) return false;
    switch (step) {
      case AMOUNTS_STEP:
        return donationType && selectedPriceAmt;
      case INFORMATION_STEP:
        return email && firstName && lastName && !emailError;
      case PAYMENT_STEP:
        return true;
      default:
        return true;
    }
  };

  const handleNext = () => {
    switch (step) {
      case AMOUNTS_STEP:
        setStep(INFORMATION_STEP);
        return;
      case INFORMATION_STEP:
        registerUser();
        setStep(PAYMENT_STEP);
        return;
      case PAYMENT_STEP: {
        if (paymentMethod === CHECK) setStep(THANKYOU_STEP);
        else handleSubmit();

        return;
      }
      default:
        close();
    }
  };
  const handleBack = () => {
    switch (step) {
      case AMOUNTS_STEP:
        close();
        return;
      case INFORMATION_STEP:
        setStep(AMOUNTS_STEP);
        return;
      case PAYMENT_STEP:
        setStep(INFORMATION_STEP);
        return;
    }
  };
  const renderAmountsStep = () => (
    <>
      <div className="font-medium text-base text-gray-400 mb-4">
        <div className="font-medium text-left text-sm text-gray-800 opacity-75 mb-1">
          Select your donation amount.
        </div>
        <div className="flex flex-wrap justify-between">
          {(org.data().prices || statePrices).map((price, index) => (
            <div
              key={price.id}
              id={`price-item-${index}`}
              className={`flex-1 text-white text-center rounded py-2 mt-2 
              ${
                ({
                  0: 'mr-2 sm:mr-0',
                  1: ''
                } as any)[index % 2]
              } 
              ${
                ({
                  0: 'sm:mr-2',
                  1: '',
                  2: 'sm:ml-2'
                } as any)[index % 3]
              } mb-1 cursor-pointer ${
                price.id === selectedPriceId ? 'bg-gray-750' : 'bg-gray-600'
              }`}
              onClick={() => {
                setSelectedPriceAmt(price.amount);
                setSelectedPriceId(price.id);
              }}
              style={{
                flexGrow: 1,
                minWidth: '7.5rem'
              }}
            >
              ${Number(price.amount) / 100}
            </div>
          ))}
          {'other' === selectedPriceId ? (
            <span className="flex-1 flex text-white text-center rounded py-2 sm:ml-2 mt-2 mb-1 cursor-pointer bg-gray-750">
              <span className="ml-2">$</span>
              <input
                type="number"
                placeholder="0.00"
                value={selectedPriceAmt}
                onChange={e => {
                  setSelectedPriceAmt(e.currentTarget.value);
                }}
                className={`hide-arrows text-white text-center rounded cursor-pointer bg-gray-750 focus:outline-none`}
                style={{
                  width: '6rem'
                }}
              />
            </span>
          ) : (
            <div
              className={`flex-1 text-white text-center rounded py-2 sm:ml-2 mt-2 mb-1 cursor-pointer bg-gray-600
              `}
              onClick={() => {
                setSelectedPriceId('other');
                setSelectedPriceAmt('');
              }}
            >
              Other
            </div>
          )}
        </div>
        <div className="font-medium text-left text-sm text-gray-800 opacity-75 my-4">
          Would you like this to be a one-time or monthly donation?
        </div>
        <div className="flex justify-around">
          <div
            className={`${
              donationType === ONE_TIME_DONATION
                ? 'bg-gray-750 text-white'
                : 'text-gray-600'
            } font-medium text-base box-border rounded border-gray-600 text-center py-1 mr-4 w-1/2 cursor-pointer border`}
            style={{
              borderColor:
                donationType === ONE_TIME_DONATION ? color : '#718096'
              // color: donationType === ONE_TIME_DONATION ? color : '#718096'
            }}
            onClick={() => setDonationType(ONE_TIME_DONATION)}
          >
            One-time donation
          </div>
          <div
            className={`${
              donationType === MONTHLY_DONATION
                ? 'bg-gray-750 text-white'
                : 'text-gray-600'
            } font-medium text-base box-border rounded  text-center  py-1 w-1/2 cursor-pointer border`}
            style={{
              borderColor: donationType === MONTHLY_DONATION ? color : '#718096'
              // color: donationType === MONTHLY_DONATION ? color : '#718096'
            }}
            onClick={() => setDonationType(MONTHLY_DONATION)}
          >
            Monthly donation
          </div>
        </div>
      </div>
    </>
  );

  const renderInformationStep = () => (
    <>
      <div className="text-gray-800 opacity-75 text-left font-medium text-sm">
        Tell us a bit about you.
      </div>
      <div className="flex justify-between">
        <input
          id="first-name"
          className="form-input w-1/2 mr-2 block sm:text-sm sm:leading-5 my-2 placeholder-gray-400 text-gray-700"
          value={firstName}
          onChange={e => setFirstName(e.target.value)}
          placeholder="First Name*"
        />
        <input
          id="last-name"
          className="form-input w-1/2 block sm:text-sm sm:leading-5 my-2 placeholder-gray-400 text-gray-700"
          value={lastName}
          onChange={e => setLastName(e.target.value)}
          placeholder="Last Name*"
        />
      </div>
      <input
        id="email"
        className={`form-input block w-full sm:text-sm sm:leading-5 placeholder-gray-400  ${
          emailError ? 'border-red-400 text-red-400' : 'text-gray-700'
        }`}
        value={email}
        onChange={e => setEmail(e.target.value)}
        placeholder="Email Address*"
      />
      {emailError && (
        <div className="text-left text-xs text-red-400">
          Please enter a valid email address.
        </div>
      )}
      <div className="text-gray-800 opacity-75 text-left font-medium text-sm mt-4">
        Share a public message with{' '}
        {isInHomePage
          ? stateObj?.meta_title
          : stateObj?.hasStateWideSite
          ? 'the newspaper'
          : 'the foundation'}
        .
      </div>
      <textarea
        className="form-input block w-full sm:text-sm sm:leading-5 my-2 placeholder-gray-400 text-gray-700"
        rows={3}
        placeholder="Why do you support local news?"
        value={message}
        onChange={e => setMessage(e.target.value)}
      />
      {stateObj.hasSubscribers && (
        <div
          className="flex items-center my-4"
          onClick={() => !isAnonymous && setIsSubscriber(!isSubscriber)}
        >
          <input
            id="subscriber"
            type="checkbox"
            className="form-checkbox h-4 w-4 text-gray-600 transition duration-150 ease-in-out"
            disabled={isAnonymous}
            checked={isSubscriber}
          />
          <label
            className={`ml-4 mr-8 block text-sm leading-4 ${
              isAnonymous ? 'text-gray-600' : 'text-gray-900'
            } text-left`}
          >
            I would like to receive more information from the New York Newspaper
            Foundation about news and literacy programming.
          </label>
        </div>
      )}
      <div
        className="flex items-center my-4"
        onClick={() => {
          setIsAnonymous(!isAnonymous);
          setIsSubscriber(false);
        }}
      >
        <input
          id="remember_me"
          type="checkbox"
          checked={isAnonymous}
          className="form-checkbox h-4 w-4 text-gray-600 transition duration-150 ease-in-out"
        />
        <label className="ml-4 block text-sm leading-4 text-gray-900">
          Keep my donation anonymous.
        </label>
      </div>
    </>
  );
  const renderPaymentStep = () => (
    <>
      <div className="text-gray-800 opacity-75 text-left font-medium text-sm">
        Review your donation.
      </div>
      <div className="text-gray-800 opacity-75 text-left text-lg">
        {`$${
          selectedPriceId === 'other'
            ? Number(selectedPriceAmt).toFixed(2)
            : (Number(selectedPriceAmt) / 100).toFixed(2)
        } ${
          donationType === MONTHLY_DONATION ? 'per month' : 'one-time donation'
        }`}
      </div>
      <div className="text-gray-800 opacity-75 text-left font-medium text-sm mt-4">
        How would you like to pay?
      </div>
      <div className="flex justify-between my-2">
        <div
          className={`${
            paymentMethod === CARD ? 'bg-gray-750 text-white' : 'text-gray-600'
          } flex items-center w-1/2 mr-2 py-1 border rounded text-base cursor-pointer`}
          style={{
            borderColor: paymentMethod === CARD ? color : '#6B7280'
            // color: paymentMethod === CARD ? color : '#6B7280'
          }}
          onClick={() => setPaymentMethod(CARD)}
          id="pay-with-card"
        >
          <Card
            selected={paymentMethod === CARD}
            color={paymentMethod === CARD ? 'white' : color || ''}
          />
          Card
        </div>
        <div
          className={`${
            paymentMethod === MONTHLY_DONATION
              ? 'bg-gray-750 text-white'
              : 'text-gray-600'
          } flex items-center w-1/2 py-1 border rounded text-base ${
            donationType === ONE_TIME_DONATION
              ? 'cursor-pointer'
              : 'cursor-not-allowed tooltip'
          }`}
          style={{
            borderColor: paymentMethod === CHECK ? color : '#6B7280'
          }}
          onClick={() =>
            donationType === ONE_TIME_DONATION && setPaymentMethod(CHECK)
          }
        >
          <Bank
            selected={paymentMethod === CHECK}
            color={paymentMethod === MONTHLY_DONATION ? 'white' : color || ''}
          />
          Check
          {donationType === MONTHLY_DONATION && (
            <span className="tooltiptext">
              Checks are not a valid form of payment for recurring donations.
            </span>
          )}
        </div>
      </div>
      {paymentMethod === CARD && (
        <div className="rounded-md border px-4 py-2 mb-1">
          <CardElement />
        </div>
      )}
      {paymentMethod === CHECK && (
        <div className="rounded-md border px-4 py-2 mb-1 text-left text-xs text-blue-900 whitespace-pre-wrap">
          {getCheckText()}
          <br />
          <br />
          {stateObj.check_address}
        </div>
      )}
    </>
  );
  const renderThankYouStep = () => (
    <>
      <div className="text-gray-800 opacity-75 text-left font-medium text-sm my-4">
        Success! We appreciate your support. Check your email for a
        confirmation.
      </div>
      <ThankYou />
    </>
  );
  return (
    <div className="fixed bottom-0 z-20 inset-x-0  sm:inset-0 flex items-center justify-center">
      <div className="fixed inset-0 transition-opacity">
        <div
          className="absolute inset-0 bg-gray-500 opacity-75"
          onClick={() => close()}
        ></div>
      </div>

      <form
        className="bg-white w-md rounded-lg overflow-hidden shadow-xl transform transition-all"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
        onSubmit={handleSubmit}
      >
        <div>
          <div className="font-semibold text-xl text-left text-gray-800 opacity-75 mb-4 bg-gray-100 py-5 px-6 break-words">
            {step === THANKYOU_STEP
              ? `Thanks for your donation, ${firstName}!`
              : isInHomePage
              ? `Support local journalism in ${stateObj?.label}`
              : `Show your support for ${name}`}
          </div>
          <div className="px-6 pb-8">
            {step === AMOUNTS_STEP && renderAmountsStep()}
            {step === INFORMATION_STEP && renderInformationStep()}
            {step === PAYMENT_STEP && renderPaymentStep()}
            {step === THANKYOU_STEP && renderThankYouStep()}
          </div>
          {err && (
            <div className="px-8 pb-2">
              <div className="text-left text-red-500">{err}</div>
            </div>
          )}
          <div className="flex justify-end items-center bg-gray-100 px-2 py-3">
            {step !== THANKYOU_STEP && (
              <button
                type="button"
                className="rounded-md font-semibold text-sm px-6 py-2 focus:outline-none"
                style={{ color: color }}
                onClick={handleBack}
              >
                {step === AMOUNTS_STEP ? 'Cancel' : 'Back'}
              </button>
            )}
            <button
              type="button"
              className={`rounded-md font-semibold text-sm text-white px-6 py-2 mr-3 ${
                !canGoNext() && 'opacity-25 cursor-not-allowed'
              }`}
              style={{ background: color }}
              onClick={handleNext}
              disabled={!canGoNext()}
              id="next"
            >
              {step === PAYMENT_STEP
                ? 'Submit'
                : step === THANKYOU_STEP
                ? 'Done'
                : 'Next'}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PaymentModal;
