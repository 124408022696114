import React from 'react';
import { EDonationProfile, ESnapshotExists } from '../../types';

const FAQSection = ({
  org,
  stateObj
}: {
  org: ESnapshotExists<EDonationProfile>;
  stateObj: Record<string, any>;
}) => {
  const { color, name, goals, quote } = org.data();
  const { stateGoals, FAQs, pressQuote, goalsSection } = stateObj;

  return (
    <div className="pb-16 pt-8 bg-gray-100 overflow-hidden">
      <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
        <svg
          className="hidden lg:block absolute left-full transform -translate-x-1/2 translate-y-2/5"
          width="404"
          height="784"
          fill="none"
          viewBox="0 0 404 784"
        >
          <defs>
            <pattern
              id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect
                x="0"
                y="0"
                width="4"
                height="4"
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width="354"
            height="300"
            fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
          />
        </svg>

        <div className="relative mt-6 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div className="relative text-left">
            <h4 className="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
              {goalsSection && goalsSection.title
                ? goalsSection.title
                : 'Help us tell stories that matter'}
            </h4>
            <p className="mt-3 font-normal text-base text-gray-600">
              {goalsSection && goalsSection.description
                ? goalsSection.description
                : `By donating to ${name}, you help journalists keep citizens informed, hold officials accountable and cover the most important topics in our community. Your contributions will equip our newsroom to better serve you and all our readers, starting with...`}
            </p>

            <ul className="mt-3">
              {((stateGoals ? stateGoals : goals) as string[]).map((g, i) => (
                <li className={i > 0 ? 'mt-3' : ''}>
                  <div className="flex">
                    <div className="flex-shrink-0 mt-2">
                      <svg width="7" height="7" viewBox="0 0 7 7" fill="none">
                        <circle cx="3.5" cy="3.5" r="3.5" fill={color} />
                      </svg>
                    </div>
                    <div className="ml-4">
                      <h5 className="text-base text-gray-600 leading-6">{g}</h5>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
            <p className="mt-3 font-normal text-base text-gray-600">
              {goalsSection && goalsSection.postGoalsParagraph
                ? goalsSection.postGoalsParagraph
                : 'Spread the news: A little bit goes a long way. With your help, we can keep the presses running through hard times. We thank you for your contribution.'}
            </p>
          </div>

          <div className="z-1 mt-10 lg:-mx-4 relative lg:mt-0">
            <div className="bg-gray-100 overflow-hidden sm:rounded-sm w-full h-auto p-5 ml-0 lg:bg-white lg:shadow-md lg:w-4/6 lg:w-4/5 lg:ml-12">
              <div className="text-xl text-center text-gray-900">
                “{quote.text}”
              </div>
              <br />
              <div className="flex justify-center text-base pb-2">
                <div className="text-gray-900 mr-2">{quote.memberName}</div>
                <svg
                  width="8"
                  height="20"
                  viewBox="0 0 8 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M5 0H8L3 20H0L5 0Z" fill={color} />
                </svg>
                <div className="text-gray-600 ml-2">
                  {quote.title}, {name}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="relative flex flex-col items-center mt-20 mb-2">
          <svg
            className="hidden lg:block absolute right-full transform translate-x-full -translate-y-4/5"
            width="404"
            height="784"
            fill="none"
            viewBox="0 0 404 784"
          >
            <defs>
              <pattern
                id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width="304"
              height="300"
              fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
            />
          </svg>
          <div className="hidden md:block">
            <div className="text-center font-medium text-xl text-gray-900 max-w-2xl md:block">
              “{pressQuote.text}”
            </div>
            <br />
            <div className="flex justify-center text-base pb-2">
              <div className="text-gray-900 text-base mr-2">
                {pressQuote.memberName}
              </div>
              <svg
                width="8"
                height="20"
                viewBox="0 0 8 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M5 0H8L3 20H0L5 0Z" fill={color} />
              </svg>
              <div className="text-gray-600 ml-2">{pressQuote.title}</div>
            </div>
          </div>
        </div>
        {FAQs && (
          <div className="max-w-screen-xl text-left mx-auto mt-14 pt-12 sm:pt-16 pr-4 sm:pr-6 lg:pt-20 lg:pr-8">
            <h2 className="text-3xl leading-9 font-extrabold text-gray-900">
              Frequently asked questions
            </h2>
            <div className="md:border md:border-solid md:mt-6"></div>
            <div className="border-t-2 border-gray-100">
              <div className="flex whitespace-pre-wrap flex-wrap">
                {FAQs.map((FAQ: { a: string; q: string }, index: number) => (
                  <div
                    key={FAQ.q}
                    className={`mt-6 md:w-47
                ${
                  ({
                    0: 'md:mr-16',
                    1: ''
                  } as any)[index % 2]
                } `}
                  >
                    <div className="text-lg leading-6 font-medium text-gray-900">
                      {FAQ.q}
                    </div>
                    <div className="mt-2">
                      <p className="text-base leading-6 text-gray-600">
                        {FAQ.a}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        <div className="text-left">
          <div className="max-w-7xl mx-auto mt-16 pt-16">
            <div className="max-w-lg mx-auto md:max-w-none md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h2 className="text-2xl leading-8 font-extrabold text-gray-900 sm:text-3xl sm:leading-9">
                  General Support
                </h2>
                <div className="mt-3">
                  <p className="text-lg leading-7 text-gray-500 whitespace-pre-wrap">
                    {stateObj.contactSection && stateObj.contactSection.general
                      ? stateObj.contactSection.general
                      : `If you have general questions about this campaign, please reach out to the ${stateObj.meta_title}.`}
                  </p>
                </div>
                <div className="mt-9">
                  <div className="mt-6 flex">
                    <div className="flex-shrink-0">
                      <svg
                        className="h-6 w-6 text-gray-400"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                        />
                      </svg>
                    </div>
                    <div className="ml-3 text-base leading-6 text-gray-500">
                      <p>{stateObj.support_email}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-12 sm:mt-16 md:mt-0">
                <h2 className="text-2xl leading-8 font-extrabold text-gray-900 sm:text-3xl sm:leading-9">
                  Technical Support
                </h2>
                <div className="mt-3">
                  <p className="text-lg leading-7 text-gray-500">
                    If you have technical questions about the platform or making
                    a donation, please reach out to the Column team.
                  </p>
                </div>
                <div className="mt-9">
                  <div className="mt-6 flex">
                    <div className="flex-shrink-0">
                      <svg
                        className="h-6 w-6 text-gray-400"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                        />
                      </svg>
                    </div>
                    <div className="ml-3 text-base leading-6 text-gray-500">
                      <p>support@column.us</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="relative mt-6 sm:mt-8 lg:mt-12">
          <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center"></div>
        </div>
      </div>
    </div>
  );
};
export default FAQSection;
