import FIREBASE_CONFIG from './config.firebase';

const PROD = 'PROD';
const DEMO = 'DEMO';
const STAGING = 'STAGING';
const TEST = 'TEST';
const DEV = 'DEV';

// determine the corresponding environment
export const ENV =
  (process.env.NODE_ENV === 'production' &&
    FIREBASE_CONFIG.projectId === 'enotice-production' &&
    PROD) ||
  (window.location.host.split('.')[0] === 'staging' && STAGING) ||
  (FIREBASE_CONFIG.projectId === 'enotice-demo-8d99a' && DEMO) ||
  (process.env.NODE_ENV === 'test' && TEST) ||
  DEV;

// Stripe
export const DEV_STRIPE_VARS = {
  transfer: 'https://dashboard.stripe.com/test/connect/accounts/',
  key: 'pk_test_lHCdsg49uFGcdXruY4mpo0Y6008ZDcTc2P'
};
const LIVE_STRIPE_VARS = {
  transfer: 'https://dashboard.stripe.com/connect/accounts/',
  key: 'pk_live_rcQzdsMuxEALkEV9qiOcyLBi001yQrpvs5'
};

export const STRIPE_VARS = ENV === PROD ? LIVE_STRIPE_VARS : DEV_STRIPE_VARS;

export const Functions = {
  donate: 'donate',
  createCustomer: 'createCustomer',
  createBillingSession: 'createBillingSession'
};
