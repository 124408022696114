export const SubdomainStatus = {
  pending: {
    value: 1,
    label: 'Pending',
    key: 'pending'
  },
  live: {
    value: 2,
    label: 'Live',
    key: 'live'
  }
};

export type Quote = {
  text: string;
  memberName: string;
  title: string;
};

export type EOrganization = {
  state: number;
  website?: string;
};

export type EDonationProfile = {
  organization: ERef<EOrganization>;
  name: string;
  city: string;
  state: number;
  about: string;
  domain: string;
  logoUrl: string;
  website: string;
  facebookLink?: string;
  twitterLink?: string;
  color?: string;
  pdfUrl: string;
  quote: Quote;
  goals?: string[];
  subdomain?: string;
  subdomainStatus?: number;
  legalDocs: { url: string; fileName: string; downloaded?: boolean }[];
  _geoloc?: {
    lat: number;
    lng: number;
  };
  prices: {
    amount: string;
    id: string;
  }[];
};
export type OrderByDirection = 'desc' | 'asc';

export type WhereFilterOp =
  | '<'
  | '<='
  | '=='
  | '!='
  | '>='
  | '>'
  | 'array-contains'
  | 'in'
  | 'array-contains-any'
  | 'not-in';

  export type EUnsubscribe = () => void;

interface ESnapshotBase<T> {
  id: string;
  ref: ERef<T>;

  proto?: {
    createTime: string;
    updateTime: string;
  };
}

export interface ESnapshotNotExists<T> extends ESnapshotBase<T> {
  exists: boolean;
  data(): T | undefined;
}

export interface ESnapshotExists<T> extends ESnapshotBase<T> {
  exists: boolean;
  data(): T;
}

/**
 * See firestore "DocumentSnapshot"
 */
export type ESnapshot<T> = ESnapshotExists<T> | ESnapshotNotExists<T>;

/**
 * See firestore "DocumentReference"
 */
export interface ERef<T> {
  id: string;
  parent: ECollectionRef<T>;
  path: string;
  collection(collectionPath: string): ECollectionRef<any>;
  update(update: Partial<T>): Promise<any>;
  create?(update: Partial<T>): Promise<any>;
  set(update: Partial<T>): Promise<unknown>;
  get(): Promise<ESnapshot<T>>;
  delete(): Promise<any>;
  onSnapshot(
    onNext: (snapshot: ESnapshot<T>) => void,
    onError?: (err: any) => void
  ): EUnsubscribe;
}

/**
 * See firestore "Query"
 */
export interface EQuery<T> {
  where(fieldPath: string, opStr: WhereFilterOp, value: any): EQuery<T>;
  orderBy(fieldPath: Object, directionStr?: OrderByDirection): EQuery<T>;
  limit(limit: number): EQuery<T>;
  limitToLast(limit: number): EQuery<T>;
  startAt(...fieldValues: any[]): EQuery<T>;
  startAfter(...fieldValues: any[]): EQuery<T>;
  endBefore(...fieldValues: any[]): EQuery<T>;
  endAt(...fieldValues: any[]): EQuery<T>;
  get(): Promise<EQuerySnapshot<T>>;

  onSnapshot(
    onNext: (snapshot: EQuerySnapshot<T>) => void,
    onError?: (error: any) => void
  ): EUnsubscribe;
}

/**
 * See firestore "QuerySnapshot"
 */
export interface EQuerySnapshot<T> {
  docs: Array<ESnapshotExists<T>>;
  size: number;
  empty: boolean;
}

/**
 * See firestore "CollectionReference"
 */
export interface ECollectionRef<T> extends EQuery<T> {
  id: string;
  parent: ERef<any> | null;
  path: string;

  doc(documentPath?: string): ERef<T>;
  add(data: T): Promise<ERef<T>>;
}

interface ESnapshotBase<T> {
  id: string;
  ref: ERef<T>;

  proto?: {
    createTime: string;
    updateTime: string;
  };
}

export interface ESnapshotNotExists<T> extends ESnapshotBase<T> {
  exists: boolean;
  data(): T | undefined;
}

export interface ESnapshotExists<T> extends ESnapshotBase<T> {
  exists: boolean;
  data(): T;
}