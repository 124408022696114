import React from 'react';

export const Card = ({
  selected,
  color
}: {
  selected: boolean;
  color: string;
}) => {
  return (
    <svg
      className="mx-2"
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
    >
      <path
        d="M1.25 5.5H14.75H1.25ZM4.25 9.25H5H4.25ZM8 9.25H8.75H8ZM3.5 12.25H12.5C13.0967 12.25 13.669 12.0129 14.091 11.591C14.5129 11.169 14.75 10.5967 14.75 10V4C14.75 3.40326 14.5129 2.83097 14.091 2.40901C13.669 1.98705 13.0967 1.75 12.5 1.75H3.5C2.90326 1.75 2.33097 1.98705 1.90901 2.40901C1.48705 2.83097 1.25 3.40326 1.25 4V10C1.25 10.5967 1.48705 11.169 1.90901 11.591C2.33097 12.0129 2.90326 12.25 3.5 12.25Z"
        stroke={selected ? color : '#4A5568'}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const Bank = ({
  selected,
  color
}: {
  selected: boolean;
  color: string;
}) => {
  return (
    <svg
      className="mx-2"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
    >
      <path
        d="M4.61111 8.94444V11.1111M7.5 8.94444V11.1111M10.3889 8.94444V11.1111M1 14H14M1 6.05556H14M1 3.88889L7.5 1L14 3.88889M1.72222 6.05556H13.2778V14H1.72222V6.05556Z"
        stroke={selected ? color : '#4A5568'}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const ThankYou = () => (
  <svg
    width="371"
    height="267"
    viewBox="0 0 371 267"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.1"
      d="M347.208 171.944C339.035 145.308 349.46 114.635 361.732 91.5387C365.839 83.7936 370.373 75.6739 370.943 65.9593C371.629 54.0757 366.053 43.1528 359.437 36.0118C347.371 22.9985 330.892 18.9749 316.531 25.542C304.122 31.2149 293.688 44.0288 281.17 49.1942C260.236 57.8456 238.046 43.5032 220.119 25.6326C207.428 12.9999 193.838 -2.39371 178.54 0.312866C167.776 2.21591 158.64 13.1208 152.439 25.9588C149.446 32.1574 146.842 39.105 142.483 43.2857C139.892 45.7748 136.848 47.0918 133.804 48.0343C106.008 56.6071 76.5082 35.6856 49.0807 46.4454C30.5543 53.6951 15.7834 74.8402 6.5976 99.1691C-2.58817 123.498 22.0713 144.728 18.5 171.944C15.9628 191.277 -3.83215 208.72 2 226.5C8.1713 245.325 15.1436 258.168 29.5 260.307C43.8564 262.445 50.274 256.482 63.5 248.157C80.0252 237.73 101.463 241.851 119.5 241.803C131.784 241.767 142.295 242.968 154.039 248.157C173.675 256.827 194.094 256.331 214.441 260.307C233.922 264.113 253.596 267.653 273.194 265.406C287.061 263.817 299.307 256.168 312.763 252.628C321.535 250.32 330.348 252.024 338.956 248.157C349.557 243.372 360.459 232.286 361.045 214.827C361.598 198.207 351.541 186.075 347.208 171.944Z"
      fill="#5555E3"
      fill-opacity="0.6"
    />
    <path
      d="M233.394 133.46C233.394 174.153 209.184 188.361 179.32 188.361C149.455 188.361 125.245 174.153 125.245 133.46C125.245 92.7674 179.32 41 179.32 41C179.32 41 233.394 92.7674 233.394 133.46Z"
      fill="#F2F2F2"
    />
    <path
      d="M177.433 182.29L177.989 148.197L201.139 106.019L178.076 142.849L178.326 127.519L194.281 97.0037L178.392 123.462L178.842 95.8913L195.927 71.5971L178.913 91.5557L179.194 41L177.428 107.927L177.573 105.166L160.203 78.6867L177.294 110.466L175.676 141.258L175.628 140.441L155.603 112.575L175.567 143.328L175.364 147.179L175.328 147.237L175.345 147.553L171.238 225.675H176.725L177.383 185.324L197.299 154.646L177.433 182.29Z"
      fill="#3F3D56"
    />
    <path
      opacity="0.6"
      d="M139.159 188.993C144.049 188.993 148.013 185.029 148.013 180.139C148.013 175.249 144.049 171.285 139.159 171.285C134.269 171.285 130.305 175.249 130.305 180.139C130.305 185.029 134.269 188.993 139.159 188.993Z"
      fill="#5555E3"
    />
    <path
      d="M137.003 187.627C137.87 186.925 137.321 184.809 135.776 182.901C134.231 180.992 132.276 180.015 131.408 180.717C130.541 181.419 131.09 183.535 132.635 185.443C134.18 187.352 136.135 188.329 137.003 187.627Z"
      fill="white"
    />
    <path
      d="M80.9734 163.063C85.8635 163.063 89.8277 159.098 89.8277 154.208C89.8277 149.318 85.8635 145.354 80.9734 145.354C76.0833 145.354 72.1191 149.318 72.1191 154.208C72.1191 159.098 76.0833 163.063 80.9734 163.063Z"
      fill="#3F3D56"
    />
    <path
      d="M76.861 153.071C77.5002 150.701 77.1448 148.543 76.0673 148.253C74.9899 147.962 73.5982 149.648 72.9591 152.019C72.3199 154.389 72.6752 156.547 73.7527 156.837C74.8302 157.128 76.2218 155.441 76.861 153.071Z"
      fill="white"
    />
    <path
      d="M67.6921 107.408C70.8357 107.408 73.3841 104.859 73.3841 101.715C73.3841 98.5719 70.8357 96.0234 67.6921 96.0234C64.5484 96.0234 62 98.5719 62 101.715C62 104.859 64.5484 107.408 67.6921 107.408Z"
      fill="#3F3D56"
    />
    <path
      d="M65.1529 101.221C65.5512 99.7438 65.3298 98.3994 64.6583 98.2184C63.9868 98.0373 63.1196 99.0881 62.7212 100.565C62.3229 102.043 62.5443 103.387 63.2158 103.568C63.8873 103.749 64.7546 102.698 65.1529 101.221Z"
      fill="white"
    />
    <path
      d="M131.254 225.676C134.223 225.676 136.63 223.127 136.63 219.984C136.63 216.84 134.223 214.292 131.254 214.292C128.285 214.292 125.878 216.84 125.878 219.984C125.878 223.127 128.285 225.676 131.254 225.676Z"
      fill="#3F3D56"
    />
    <path
      d="M128.885 219.318C129.284 217.84 129.062 216.496 128.391 216.315C127.719 216.134 126.852 217.185 126.454 218.662C126.055 220.139 126.277 221.484 126.948 221.665C127.62 221.846 128.487 220.795 128.885 219.318Z"
      fill="white"
    />
    <path
      d="M297.271 182.669C302.161 182.669 306.126 178.705 306.126 173.815C306.126 168.925 302.161 164.96 297.271 164.96C292.381 164.96 288.417 168.925 288.417 173.815C288.417 178.705 292.381 182.669 297.271 182.669Z"
      fill="#3F3D56"
    />
    <path
      d="M290.947 178.242C291.995 178.242 292.845 176.26 292.845 173.815C292.845 171.37 291.995 169.388 290.947 169.388C289.899 169.388 289.05 171.37 289.05 173.815C289.05 176.26 289.899 178.242 290.947 178.242Z"
      fill="white"
    />
    <path
      d="M268.179 125.748C273.069 125.748 277.033 121.784 277.033 116.894C277.033 112.004 273.069 108.04 268.179 108.04C263.288 108.04 259.324 112.004 259.324 116.894C259.324 121.784 263.288 125.748 268.179 125.748Z"
      fill="#5555E3"
      fill-opacity="0.8"
    />
    <path
      d="M266.061 113.671C268.049 112.231 269.13 110.33 268.475 109.427C267.821 108.523 265.678 108.958 263.69 110.398C261.702 111.838 260.621 113.739 261.275 114.642C261.93 115.546 264.072 115.111 266.061 113.671Z"
      fill="white"
    />
    <path
      opacity="0.6"
      d="M290.947 73.8875C294.091 73.8875 296.639 71.3391 296.639 68.1955C296.639 65.0518 294.091 62.5034 290.947 62.5034C287.803 62.5034 285.255 65.0518 285.255 68.1955C285.255 71.3391 287.803 73.8875 290.947 73.8875Z"
      fill="#5555E3"
    />
    <path
      d="M249.521 216.189C252.49 216.189 254.897 213.782 254.897 210.813C254.897 207.844 252.49 205.437 249.521 205.437C246.552 205.437 244.146 207.844 244.146 210.813C244.146 213.782 246.552 216.189 249.521 216.189Z"
      fill="#3F3D56"
    />
    <path
      d="M247.959 208.661C249.198 207.764 249.872 206.58 249.464 206.016C249.056 205.453 247.721 205.724 246.482 206.622C245.243 207.519 244.569 208.704 244.977 209.267C245.385 209.83 246.72 209.559 247.959 208.661Z"
      fill="white"
    />
    <g clip-path="url(#clip0)">
      <path
        d="M296.879 224.894H203.591L203.633 214.998C203.739 189.955 223.716 169.321 248.739 168.895C249.01 168.89 249.281 168.888 249.552 168.888H249.552C255.183 168.883 260.769 169.888 266.046 171.853C283.752 178.451 295.637 195.215 296.428 214.104L296.879 224.894Z"
        fill="#A19DB9"
      />
      <path
        d="M325.731 224.895H172.046V225.279H325.731V224.895Z"
        fill="#2F2E41"
      />
      <path
        d="M248.655 180.629L252.766 183.621L256.876 218.769H266.592L263.229 178.76L249.776 173.525L248.655 180.629Z"
        fill="#2F2E41"
      />
      <path
        d="M231.465 169.412C231.465 169.412 230.344 182.125 234.829 182.499C239.313 182.873 256.503 178.76 256.503 178.76L264.724 207.925L277.056 201.569C277.056 201.569 269.582 168.664 265.097 166.795C260.613 164.925 245.666 161.186 245.666 161.186L234.829 163.056L231.465 169.412Z"
        fill="#2F2E41"
      />
      <path
        d="M258.371 218.021C258.371 218.021 256.128 226.621 257.623 227.369C258.522 227.818 259.396 228.318 260.239 228.865V227.369C260.239 227.369 262.107 229.238 262.107 230.734C262.107 232.23 272.197 232.604 272.197 230.734C272.197 228.865 271.823 226.621 269.955 225.499C268.087 224.378 265.844 218.021 265.844 218.021H258.371Z"
        fill="#2F2E41"
      />
      <path
        d="M269.208 202.69L269.955 209.795L272.571 214.282L278.924 207.177L274.066 199.325L269.208 202.69Z"
        fill="#2F2E41"
      />
      <path
        d="M275.187 209.795C275.187 209.795 276.563 205.087 277.93 205.571C279.298 206.055 284.529 210.916 284.529 210.916L288.266 213.16C288.266 213.16 295.74 213.908 293.498 216.151C291.256 218.395 283.035 221.386 279.298 219.89C275.561 218.395 274.813 218.021 274.813 218.021L273.692 218.768L269.208 217.647L269.955 209.795C269.955 209.795 271.076 213.16 275.187 209.795Z"
        fill="#2F2E41"
      />
      <path
        d="M244.171 122.673C248.918 122.673 252.766 118.822 252.766 114.073C252.766 109.323 248.918 105.473 244.171 105.473C239.424 105.473 235.576 109.323 235.576 114.073C235.576 118.822 239.424 122.673 244.171 122.673Z"
        fill="#9F616A"
      />
      <path
        d="M238.752 119.12C238.752 119.12 239.5 126.972 237.257 126.972C235.015 126.972 237.631 136.694 243.61 136.694C249.589 136.694 251.831 125.103 251.831 125.103C251.831 125.103 247.721 123.607 248.468 118.746C249.216 113.885 238.752 119.12 238.752 119.12Z"
        fill="#9F616A"
      />
      <path
        d="M238.157 126.374L235.016 127.72L226.49 131.33C226.146 131.475 225.859 131.728 225.67 132.05C225.482 132.373 225.403 132.747 225.444 133.118L228.663 161.747C228.663 161.747 225.3 168.851 230.532 169.225L231.279 171.842L237.258 164.738L259.679 163.99L260.053 127.72L249.794 123.664C249.794 123.664 248.399 131.383 238.157 126.374Z"
        fill="#3F3D56"
      />
      <path
        d="M227.729 132.394L226.348 131.999C226.193 131.955 226.03 131.944 225.871 131.967C225.712 131.991 225.559 132.047 225.423 132.134C225.287 132.221 225.171 132.335 225.083 132.47C224.995 132.604 224.936 132.756 224.911 132.915L221.246 155.916C220.96 157.619 221.234 159.37 222.026 160.904C223.158 163.049 225.51 164.995 230.344 162.681L233.708 138.377L227.729 132.394Z"
        fill="#3F3D56"
      />
      <path
        d="M255.756 127.16L260.24 127.907L272.572 145.108L273.693 148.099L280.793 145.855C280.793 145.855 280.419 149.221 284.156 148.847C284.156 148.847 279.672 161.186 270.33 157.447C260.988 153.708 257.998 142.49 257.998 142.49L255.756 127.16Z"
        fill="#3F3D56"
      />
      <path
        d="M260.987 134.638L244.171 127.907L243.424 130.151L251.271 136.881L260.987 134.638Z"
        fill="#CCCCCC"
      />
      <path
        d="M238.996 114.621C239.313 114.536 239.36 114.115 239.371 113.786C239.428 112.026 240.486 110.221 242.17 109.709C242.816 109.531 243.492 109.494 244.154 109.599C245.098 109.725 246.009 110.03 246.84 110.496C247.278 110.743 247.708 111.04 248.206 111.115C248.534 111.165 249.956 111.579 250.283 111.637C251.002 111.765 251.67 112.409 252.36 112.169C253.02 111.94 253.167 111.085 253.183 110.386C253.219 108.793 252.011 106.59 250.947 105.405C250.139 104.506 248.923 104.091 247.735 103.875C246.347 103.652 244.94 103.567 243.535 103.62C241.632 103.654 239.678 103.801 237.963 104.624C236.247 105.448 234.807 107.087 234.725 108.988C234.708 109.384 234.748 109.782 234.715 110.177C234.634 111.141 234.122 112.012 233.882 112.949C233.711 113.636 233.685 114.352 233.807 115.05C233.929 115.748 234.196 116.413 234.59 117.002C235.227 117.939 237.772 118.79 237.735 119.922C238.195 119.445 237.11 118.965 237.571 118.488C237.777 118.331 237.923 118.107 237.984 117.855C238.045 117.603 238.018 117.338 237.908 117.103L237.419 115.196C237.33 114.847 237.242 114.469 237.377 114.135C237.9 112.84 238.467 114.762 238.996 114.621Z"
        fill="#2F2E41"
      />
      <path
        d="M243.423 130.151L236.323 157.821L263.976 167.542L280.045 154.081L290.134 123.794L273.692 135.76L243.423 130.151Z"
        fill="#F2F2F2"
      />
      <path
        d="M244.779 133.148L244.684 133.51L271.589 140.614L271.685 140.253L244.779 133.148Z"
        fill="#E6E6E6"
      />
      <path
        d="M244.032 135.018L243.937 135.379L270.842 142.484L270.938 142.122L244.032 135.018Z"
        fill="#E6E6E6"
      />
      <path
        d="M243.658 136.888L243.562 137.249L270.468 144.353L270.563 143.992L243.658 136.888Z"
        fill="#E6E6E6"
      />
      <path
        d="M243.285 138.757L243.189 139.119L270.095 146.223L270.19 145.862L243.285 138.757Z"
        fill="#E6E6E6"
      />
      <path
        d="M242.911 140.627L242.815 140.988L269.721 148.093L269.816 147.731L242.911 140.627Z"
        fill="#E6E6E6"
      />
      <path
        d="M242.537 142.496L242.441 142.858L269.347 149.962L269.442 149.601L242.537 142.496Z"
        fill="#E6E6E6"
      />
      <path
        d="M242.164 144.366L242.068 144.728L268.974 151.832L269.069 151.47L242.164 144.366Z"
        fill="#E6E6E6"
      />
      <path
        d="M241.79 146.235L241.694 146.597L268.6 153.701L268.695 153.34L241.79 146.235Z"
        fill="#E6E6E6"
      />
      <path
        d="M241.416 148.105L241.32 148.466L268.226 155.571L268.321 155.209L241.416 148.105Z"
        fill="#E6E6E6"
      />
      <path
        d="M232.029 150.505C232.029 150.505 240.434 145.481 244.171 148.847C247.908 152.212 233.334 157.073 233.334 157.073L232.029 150.505Z"
        fill="#9F616A"
      />
      <path
        d="M222.871 152.96L233.708 148.847C233.708 148.847 231.84 155.951 235.577 156.325L230.345 162.682C230.345 162.682 219.882 162.682 222.871 152.96Z"
        fill="#3F3D56"
      />
      <path
        d="M251.388 152.806L265.768 157.656L262.364 165.078L247.984 160.229L251.388 152.806Z"
        fill="#E6E6E6"
      />
      <path
        d="M276.869 137.069L287.706 128.842L284.717 138.938L273.506 147.164L276.869 137.069Z"
        fill="#CCCCCC"
      />
      <path
        d="M282.362 142.528L271.524 150.754L271.75 151.052L282.588 142.826L282.362 142.528Z"
        fill="#E6E6E6"
      />
      <path
        d="M281.614 146.641L270.776 154.867L271.002 155.165L281.839 146.939L281.614 146.641Z"
        fill="#E6E6E6"
      />
      <path
        d="M283.035 149.221C283.035 149.221 292.377 143.612 286.398 141.368C280.419 139.125 280.419 147.725 280.419 147.725C280.419 147.725 280.046 149.595 283.035 149.221Z"
        fill="#9F616A"
      />
      <path
        opacity="0.1"
        d="M228.289 136.694L227.915 151.277L233.707 149.623L229.036 149.781L228.289 136.694Z"
        fill="black"
      />
      <path
        d="M187.512 218.3C188.68 222.62 192.681 225.296 192.681 225.296C192.681 225.296 194.789 220.967 193.62 216.646C192.452 212.325 188.451 209.649 188.451 209.649C188.451 209.649 186.344 213.979 187.512 218.3Z"
        fill="#8985A8"
      />
      <path
        d="M189.229 217.371C192.434 220.493 192.826 225.293 192.826 225.293C192.826 225.293 188.02 225.03 184.815 221.908C181.609 218.786 181.218 213.986 181.218 213.986C181.218 213.986 186.023 214.249 189.229 217.371Z"
        fill="#3F3D56"
      />
      <path
        d="M298.226 176.586H292.455V182.36H298.226V176.586Z"
        fill="#F2F2F2"
      />
      <path
        d="M202.629 169.658C204.223 169.658 205.515 168.365 205.515 166.771C205.515 165.176 204.223 163.884 202.629 163.884C201.036 163.884 199.744 165.176 199.744 166.771C199.744 168.365 201.036 169.658 202.629 169.658Z"
        fill="#F2F2F2"
      />
    </g>
    <path
      d="M128.629 138.397L128.164 138.311C129.205 132.658 128.212 126.818 125.363 121.831C122.514 116.844 117.994 113.034 112.607 111.08C112.142 110.911 111.66 110.752 111.193 110.599C109.893 110.171 108.547 109.729 107.352 108.996C105.858 108.081 104.291 106.352 104.377 104.245L104.85 104.265C104.773 106.165 106.219 107.746 107.599 108.591C108.747 109.295 110.066 109.728 111.341 110.148C111.81 110.302 112.296 110.462 112.768 110.633C118.26 112.625 122.868 116.51 125.773 121.595C128.678 126.679 129.69 132.634 128.629 138.397Z"
      fill="#575A89"
    />
    <path
      opacity="0.6"
      d="M108.86 78.3651C113.801 87.0243 112.686 96.9351 106.622 101.827C106.537 101.897 106.449 101.966 106.362 102.032L106.641 102.343C106.652 102.355 106.662 102.367 106.671 102.38C106.837 102.566 106.96 102.788 107.028 103.028C107.096 103.269 107.109 103.522 107.065 103.769C107.022 104.015 106.922 104.248 106.775 104.45C106.628 104.652 106.437 104.818 106.217 104.934L104.279 105.95C104.055 106.064 103.81 106.128 103.56 106.141C103.31 106.153 103.06 106.112 102.827 106.02C102.594 105.929 102.382 105.79 102.206 105.611C102.029 105.432 101.892 105.218 101.803 104.982L101.788 104.941L101.608 104.457L101.566 104.348C94.0987 106.248 85.1108 101.812 80.284 93.3536C74.8077 83.7565 76.7648 72.6224 84.6567 68.483C92.5486 64.3436 103.384 68.768 108.86 78.3651Z"
      fill="#5555E3"
    />
    <path
      d="M160.723 150.262L160.938 149.882C156.311 147.253 152.757 143.075 150.911 138.092C149.064 133.11 149.043 127.646 150.853 122.674C151.01 122.244 151.183 121.809 151.351 121.388C151.82 120.214 152.304 118.999 152.494 117.718C152.731 116.116 152.434 113.98 150.889 112.787L150.622 113.132C152.016 114.209 152.279 116.172 152.06 117.651C151.878 118.882 151.403 120.072 150.944 121.224C150.775 121.647 150.6 122.086 150.441 122.522C148.596 127.591 148.617 133.162 150.5 138.242C152.383 143.322 156.006 147.582 160.723 150.262Z"
      fill="#575A89"
    />
    <path
      opacity="0.6"
      d="M139.081 91.7192C137.583 100.736 141.651 108.952 148.37 111.292C148.464 111.327 148.561 111.359 148.657 111.389L148.516 111.744C148.51 111.758 148.506 111.771 148.502 111.785C148.418 111.998 148.384 112.227 148.401 112.456C148.418 112.686 148.487 112.908 148.602 113.108C148.717 113.307 148.875 113.478 149.064 113.606C149.253 113.735 149.469 113.818 149.693 113.85L151.668 114.126C151.895 114.154 152.125 114.134 152.342 114.067C152.559 113.999 152.76 113.886 152.931 113.735C153.101 113.584 153.238 113.397 153.333 113.188C153.427 112.979 153.477 112.751 153.479 112.52L153.479 112.479L153.481 112.005L153.482 111.898C160.458 111.217 166.742 104.591 168.205 95.7828C169.866 85.789 164.693 76.7786 156.65 75.6564C148.606 74.5341 140.741 81.7254 139.081 91.7192Z"
      fill="#5555E3"
    />
    <path
      d="M123.55 196.395C123.543 203.349 121.027 210.067 116.464 215.315C111.901 220.563 105.597 223.988 98.7115 224.961C91.8256 225.934 84.82 224.389 78.9819 220.61C73.1437 216.832 68.8652 211.073 66.9326 204.393C64.9999 197.713 65.5428 190.559 68.4616 184.247C71.3804 177.935 76.479 172.889 82.8205 170.034C89.1621 167.18 96.3206 166.71 102.981 168.711C109.641 170.712 115.355 175.049 119.074 180.926C121.999 185.555 123.55 190.919 123.55 196.395Z"
      fill="#5555E3"
    />
    <path
      d="M119.074 180.925L90.5679 209.426C88.7763 206.652 78.7949 193.762 78.7949 193.762C80.0741 192.469 81.4391 191.264 82.8807 190.155L91.1576 201.191L115.736 176.613C116.982 177.942 118.1 179.386 119.074 180.925Z"
      fill="white"
    />
    <defs>
      <clipPath id="clip0">
        <rect
          width="153.685"
          height="128.387"
          fill="white"
          transform="translate(172.046 103.612)"
        />
      </clipPath>
    </defs>
  </svg>
);
