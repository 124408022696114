import React from 'react';

const PaymentTypeSelectModal: React.FC<{
  startAssociationPayment: Function;
  close: Function;
  color: string;
  stateObj: Record<string, any>;
  findMyNewspaper: Function;
}> = ({ startAssociationPayment, close, color, stateObj, findMyNewspaper }) => {
  return (
    <div className="fixed z-20 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
        &#8203;
        <div
          className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="sm:flex sm:items-start">
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3
                className="text-lg leading-6 font-medium text-gray-900"
                id="modal-headline"
              >
                Support local journalism in {stateObj.label}
              </h3>
              <div className="mt-2">
                <p className="text-sm leading-5 text-gray-500">
                  If you click “Donate,” your contribution will be distributed
                  equally among participating newspapers. Click “Find My
                  Newspaper” to donate to a specific publication of your choice.
                </p>
              </div>
            </div>
          </div>
          <div className="mt-5 sm:mt-4 sm:pl-4 sm:flex">
            <span className="flex w-full rounded-md shadow-sm sm:w-auto">
              <button
                type="button"
                className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 text-base leading-6 font-medium text-white shadow-sm focus:outline-none focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                style={{
                  background: color
                }}
                onClick={async () => {
                  await close();
                  startAssociationPayment();
                }}
              >
                Donate
              </button>
            </span>
            <span className="mt-3 flex w-full rounded-md sm:mt-0 sm:ml-3 sm:w-auto">
              <button
                type="button"
                className="inline-flex justify-center w-full px-4 py-2 text-base leading-6 font-medium  hover:text-gray-500 focus:outline-none focus:border-blue-300  transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                style={{
                  color
                }}
                onClick={() => findMyNewspaper()}
              >
                Find My Newspaper
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentTypeSelectModal;
