import { Helmet } from 'react-helmet';
import React from 'react';

type HeaderProps = {
  title: string;
  stateObj: Record<string, any>;
};

const Header: React.FC<HeaderProps> = ({ title, stateObj }) => (
  <Helmet>
    <title>{title || stateObj.meta_title}</title>
  </Helmet>
);

export default Header;
